import React from "react";
import "./styles.css";
const UseCases = ({ useCaseRef }) => {
  const image = require("./useCaseC.png");
  const image2 = require("./useCaseB.png");
  const image3 = require("./useCaseA.png");
  return (
    <div ref={useCaseRef} className="useCases" id="use-cases">
      <div className="useCasesBase">
        <h1 style={{ fontSize: 36 }}>Use cases</h1>
        <div className="useCasesGrid">
          <div className="useCaseImageBase">
            <img src={image} className="useCaseImage" alt="About image" />
          </div>
          <div className="useCaseImageBase">
            <img src={image2} className="useCaseImage" alt="About image" />
          </div>
          <div className="useCaseImageBase">
            <img src={image3} className="useCaseImage" alt="About image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
